<template>
  <div>
    <span>{{$t('t.Series')}}</span>
    <div class="mt-4 d-flex flex-column">
      <grid
        item-min-width="250px"
        class="align-start"
      >
        <div
          v-for="(v, idx) in dataSeries"
          :key="idx + '_v'"
          class="d-flex flex-row mr-2 align-center"
        >
          <column-picker
            class="mb-4"
            :clearable="false"
            :selected-col-id.sync="dataSeries[idx].col"
            @update:selected-col-id="setColumn(idx, $event)"
            :filters="{'document-types': cameleonDocType}"
            :rules="required"
            ref="columns"
          />
          <v-btn
            class="ml-2"
            v-if="computedSeries.length != 1 && !readonly"
            icon
            small
            @click="removeValue(idx)"
          >
            <v-icon small>{{$icon('i.Delete')}}</v-icon>
          </v-btn>
          <v-btn
            class="ml-2"
            v-if="idx === computedSeries.length -1 && !readonly"
            icon
            small
            color="secondary"
            @click="addValue"
          >
            <v-icon small>{{$icon('i.Plus')}}</v-icon>
          </v-btn>
        </div>
      </grid>

    </div>
  </div>

</template>

<script>
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  components: {
    Grid: () => import('@/components/grid'),
    ColumnPicker: () => import('@/components/column-picker')
  },
  computed: {
    cameleonDocType () {
      const cameleonDocType = new IncExc()
      cameleonDocType.include([`${this.targetDocTy}`])
      return cameleonDocType
    },
    computedSeries: {
      get () { return this.dataSeries },
      set (v) {
        this.dataSeries = v
        this.dataDocumentEmit()
      }
    },
    required () {
      return [v => !!v?.length || this.$t('t.IsRequired')]
    }
  },
  methods: {
    addValue () {
      this.dataSeries.push({ col: null })
      this.dataDocumentEmit()
    },
    removeValue (idx) {
      this.dataSeries.splice(idx, 1)

      if (this.dataSeries.length === 0) {
        this.dataSeries = [{ col: null }]
      }
      this.dataDocumentEmit()
    },
    setColumn (idx, value) {
      this.dataSeries[idx] = Object.assign({}, this.dataSeries[idx], { col: value })
      this.dataDocumentEmit()
    },
    emitDocument () {
      if (!this.lodash.isEqual(this.document, this.dataSeries)) {
        this.$emit('update:document', this.lodash.cloneDeep(this.dataSeries))
      }
    }
  },
  data () {
    return {
      dataSeries: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    disabled: Boolean,
    document: Array,
    readonly: Boolean,
    targetDocTy: String
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        this.dataSeries = v?.length ? this.lodash.cloneDeep(v) : [{ col: null }]
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
